import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { Normalize } from 'styled-normalize'

import GlobalStyle from '../../styles/base/global-style'

import Auth from '../Auth/Auth'
import PrivateRoute from '../PrivateRoute/PrivateRoute'
import Header from '../Header/Header'
import Home from '../../pages/Home'
import SignIn from '../../pages/SignIn'
import Join from '../../pages/Join'
import Submit from '../../pages/Submit'
import Dashboard from '../../pages/Dashboard'

export default function App() {
  return (
    <>
      <Normalize />
      <GlobalStyle />

      <Auth>
        <Router>
          <Header />

          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={SignIn} />
              <Route path="/join" component={Join} />
              <PrivateRoute path='/submit' component={Submit} />
              <PrivateRoute path='/dashboard' component={Dashboard} />
            </Switch>
          </main>
        </Router>
      </Auth>
    </>
  )
}
