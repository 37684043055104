import React from 'react'
import styled from 'styled-components'
import { colors, spaces } from '../../styles/utils'

export default function Card(props) {
  return (
    <Container>
      <Wrapper>
        {props.children}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid ${colors.white};
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 480px;
  padding: 32px ${spaces.large} 64px;
`

const Wrapper = styled.div`
`