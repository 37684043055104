import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import actions from '../../redux/actions'
import jwt from 'jsonwebtoken'

const SECRET = 'radiosecret'

const isTokenValid = (expiryDate, today) => {
  if (expiryDate <= today) return false
  return true
}

function Auth(props) {
  const dispatch = useDispatch()

  function setUserNotLoggedIn() {
    dispatch(actions.user.setUserStatus({
      isLoggedIn: false,
      isReady: true,
    }))
  }

  async function authenticateUser() {
    const token = localStorage.getItem('userToken')

    if (!token) {
      return await setUserNotLoggedIn()
    }

    return await jwt.verify(token, SECRET, (err, decoded) => {
      if (err && err.name !== 'TokenExpiredError') {
        return console.log(err)
      }

      const today = new Date()

      let expiryDate

      if (decoded) {
        expiryDate = new Date(parseInt(decoded.exp) * 1000)
      } else {
        expiryDate = err.expiredAt
      }

      if (!isTokenValid(expiryDate, today)) {
        return setUserNotLoggedIn()
      }

      dispatch(actions.user.setUserStatus({
        isLoggedIn: true,
        isReady: true,
      }))
    })
  }

  useEffect(() => {
    authenticateUser()
  })

  return (
    <>
      {props.children}
    </>
  )
}

export default Auth