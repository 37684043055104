import styled from 'styled-components'
import { ButtonTypo, InputTypo } from './typography'
import { colors, spaces } from '../utils'

export const FormRow = styled.div`
  margin-bottom: ${spaces.medium};
`

export const FormInput = styled(InputTypo)`
  border-bottom: 1px solid ${colors.darkGrey};
  padding: ${spaces.tiny} 0;
  width: 100%;
`

export const FormSubmit = styled(ButtonTypo)`
  margin-top: ${spaces.small};
  padding: ${spaces.small} ${spaces.small} 14px;
  transition: all 0.2s;
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${colors.grey};
  }

  &:disabled {
    background-color: ${colors.white};
    opacity: 0.2;
  }
`
