import React from 'react'
import styled from 'styled-components'
import { spaces } from '../../styles/utils'

export default function Sidebar(props) {
  return (
    <Container>
      <Wrapper>
        <div>
          {props.children}
        </div>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  height: calc(100% - 50px);
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: 300px;
  z-index: 1;
`

const Wrapper = styled.div`
  padding: 32px ${spaces.medium};
  width: 100%;
`