import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'

import GET_STATIONS from '../../graphql/query/get-stations'

import Content from '../Content/Content'
import Sidebar from '../Sidebar/Sidebar'

import StationSortFilter from './StationSortFilter'
import StationFilter from './StationFilter'
import StationsList from './StationsList'
import StationPageNav from './StationPageNav'
import { spaces } from '../../styles/utils'

const ITEMS_PER_PAGE = 11

function Stations() {
  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const paramAfter = params.get('after')
  const paramBefore = params.get('before')
  const paramTitle = params.get('title')
  const paramCities = params.get('cities')
  const paramCountries = params.get('countries')
  const paramSort = params.get('sort')

  const [title, setTitle] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [filterObj, setFilterObj] = useState('')

  const { data, loading, error, fetchMore } = useQuery(
    GET_STATIONS,
    {
      variables: {
        first: ITEMS_PER_PAGE,
        status: 'PENDING',
      },
    }
  )

  function setFilterState(e) {
    const target = e.target

    switch(target.name) {
      case 'title':
        setTitle(target.value)
        break;
      case 'city':
        setCity(target.value)
        break;
      case 'country':
        setCountry(target.value)
        break;
      default:
    }
  }

  function onSubmit(e) {
    e.preventDefault()

    const newFilterObj = {
      title,
      cities: city,
      countries: country,
    }

    if (JSON.stringify(filterObj) === JSON.stringify(newFilterObj)) return

    setFilterObj(newFilterObj)
  }

  function resetEventHandler(e) {
    e.preventDefault()

    const dataReset = e.currentTarget.getAttribute('data-reset')

    const newFilterObj = {
      title,
      cities: city,
      countries: country,
    }

    switch(dataReset) {
      case 'title':
        setTitle('')
        newFilterObj.title = ''
      break;
      case 'city':
        setCity('')
        newFilterObj.cities = ''
        break;
      case 'country':
        setCountry('')
        newFilterObj.countries = ''
        break;
      default:
    }

    setFilterObj(newFilterObj)
  }

  function filterEventHandler(e) {
    const target = e.target

    const newVariables = {}
    const queryArray = []

    if (paramTitle) {
      queryArray.push(`title=${paramTitle}`)
    }

    if (target.name === 'city') {
      newVariables.cities = target.value
      queryArray.push(`cities=${target.value}`)
    }

    if (target.name === 'country') {
      newVariables.countries = target.value
      queryArray.push(`countries=${target.value}`)
    }

    if (paramSort) {
      queryArray.push(`sort=${paramSort}`)
    }

    history.push(`?${queryArray.filter(x => typeof x === 'string' && x.length > 0).join('&')}`)

    refreshResults(newVariables)
    setFilterState(e)
  }

  const refreshResults = useCallback(newVariables => {
    return fetchMore({
      variables: newVariables,
      updateQuery: (_, { fetchMoreResult }) => {
        return fetchMoreResult
      },
    })
  }, [fetchMore])

  useEffect(() => {
    const newVariables = {}

    if (paramAfter) {
      newVariables.first = ITEMS_PER_PAGE
      newVariables.after = paramAfter
    }

    if (paramBefore) {
      newVariables.last = ITEMS_PER_PAGE
      newVariables.before = paramBefore
    }

    if (paramTitle) {
      newVariables.title = paramTitle
      setTitle(paramTitle)
    }

    if (paramCities) {
      newVariables.cities = paramCities
      setCity(paramCities)
    }

    if (paramCountries) {
      newVariables.countries = paramCountries
      setCountry(paramCountries)
    }

    if (paramSort) {
      newVariables.sort = paramSort
    }

    refreshResults(newVariables)
  }, [
    location,
    paramAfter,
    paramBefore,
    paramTitle,
    paramCities,
    paramCountries,
    paramSort,
    refreshResults
  ])

  useEffect(() => {
    if (!filterObj) return

    const newVariables = {}
    const queryArray = []

    if (filterObj.title) {
      newVariables.title = filterObj.title
      queryArray.push(`title=${filterObj.title}`)
    }

    if (filterObj.cities) {
      newVariables.cities = filterObj.cities
      queryArray.push(`cities=${filterObj.cities}`)
    }

    if (filterObj.countries) {
      newVariables.countries = filterObj.countries
      queryArray.push(`countries=${filterObj.countries}`)
    }

    if (paramSort) {
      queryArray.push(`sort=${paramSort}`)
    }

    history.push(`?${queryArray.filter(x => typeof x === 'string' && x.length > 0).join('&')}`)

    refreshResults(newVariables)
  }, [filterObj, history, paramSort, refreshResults])

  if (loading) {
    return (
      <div style={{ padding: `${spaces.medium} 0`, textAlign: 'center' }}>
        <span>Loading...</span>
      </div>
    )
  }

  if (error) {
    return (
      <div style={{ padding: `${spaces.medium} 0`, textAlign: 'center' }}>
        <p>{error.message}</p>
      </div>
    )
  }

  const stations = data.stations

  return (
    <section>
      <Sidebar>
        <StationSortFilter />
      </Sidebar>

      <Content>
        <StationFilter
          title={title}
          city={city}
          country={country}
          setFilterState={setFilterState}
          onSubmit={onSubmit}
          resetEventHandler={resetEventHandler}
        />

        <StationsList
          stations={stations}
          filterEventHandler={filterEventHandler}
        />

        <StationPageNav
          stations={stations}
          itemsPerPage={ITEMS_PER_PAGE}
        />
      </Content>
    </section>
  )
}

export default Stations
