import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, spaces } from '../../styles/utils'
import { NavButton } from '../../styles/base/typography'

const getCurrentPage = (stations, itemsPerPage) => {
  return Math.ceil((stations.startIndex - 1) / itemsPerPage) + 1
}

function StationPageNav(props) {
  const history = useHistory()
  const location = useLocation()

  const { itemsPerPage, stations } = props

  const params = new URLSearchParams(location.search)
  const paramSort = params.get('sort')

  if (stations.edges.length === 0) return null

  const totalPage = Math.ceil(stations.totalCount / itemsPerPage)

  function pushPrevPage() {
    const queryArray = []
    const currentPage = getCurrentPage(stations, itemsPerPage)
    queryArray.push(currentPage > 2 ? `before=${stations.pageInfo.startCursor}` : '')
    queryArray.push(paramSort ? `sort=${paramSort}` : '')
    history.push(`?${queryArray.filter(x => typeof x === 'string' && x.length > 0).join('&')}`)
  }

  function pushNextPage() {
    const queryArray = []
    queryArray.push(`after=${stations.pageInfo.endCursor}`)
    queryArray.push(paramSort ? `sort=${paramSort}` : '')
    history.push(`?${queryArray.filter(x => typeof x === 'string' && x.length > 0).join('&')}`)
  }

  return (
    <Footer>
      <PageNav>
        <StyledNavButton
          onClick={pushPrevPage}
          disabled={!stations.pageInfo.hasPreviousPage}>
          Prev
        </StyledNavButton>
        <PageInfo>
          <span>{getCurrentPage(stations, itemsPerPage)}</span>
          <Slash>/</Slash>
          <span>{totalPage}</span>
        </PageInfo>
        <StyledNavButton
          onClick={pushNextPage}
          disabled={!stations.pageInfo.hasNextPage}>
          Next
        </StyledNavButton>
      </PageNav>
    </Footer>
  )
}

const Footer = styled.footer`
  display: flex;
  height: 50px;
  justify-content: flex-end;
  width: 100%;
`

const PageNav = styled.div`
  align-items: center;
  display: flex;
`

const PageInfo = styled.div`
  align-items: center;
  display: flex;
  margin: 0 ${spaces.medium};
`

const Slash = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin-top: -2px;
  padding: 0 3px;
`

const StyledNavButton = styled(NavButton)`
  transition: color 0.1s ease;

  &:hover:not([disabled]) {
    color: ${colors.yellow};
  }

  &[disabled] {
    color: ${colors.grey};
  }
`
StationPageNav.propTypes = {
  itemsPerPage: PropTypes.number,
  stations: PropTypes.object,
}

export default StationPageNav
