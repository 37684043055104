import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'

import configureStore from './redux/store'
import App from './components/App/App'
import * as serviceWorker from './serviceWorker'

import { ApolloProvider } from '@apollo/client'
import client from './client'

const browserHistory = createBrowserHistory()
const initialState = {}
const store = configureStore(initialState, browserHistory)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
