import React from 'react'
import PropTypes from 'prop-types'

import { colors } from '../../styles/utils'

function Heart({ fill, height, width }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{height, width}}>
      <defs/>
      <path fill={fill} d="M15.41 5.843c.76 0 1.49.316 2.027.878.537.563.839 1.326.839 2.122.001.359-.062.715-.186 1.05-.164.361-.36.705-.588 1.027l-5.509 5.932-5.466-5.917a7.129 7.129 0 01-.594-1.042c-.12-.331-.181-.682-.18-1.035a3.099 3.099 0 01.538-1.814A2.875 2.875 0 017.76 5.93a2.744 2.744 0 011.813.034 2.889 2.889 0 011.43 1.168l1.018 1.5 1.024-1.5c.265-.4.619-.726 1.03-.95.412-.225.87-.342 1.334-.34zm0-1.343a3.978 3.978 0 00-1.928.487 4.192 4.192 0 00-1.489 1.373 4.148 4.148 0 00-2.057-1.599 3.95 3.95 0 00-2.561-.02 4.14 4.14 0 00-2.08 1.567 4.458 4.458 0 00-.53 4.05c.216.505.477.987.78 1.44l6.448 6.952 6.448-6.953c.309-.442.578-.914.802-1.41a4.548 4.548 0 00.224-2.054 4.48 4.48 0 00-.706-1.932 4.231 4.231 0 00-1.478-1.373 4.014 4.014 0 00-1.916-.505l.043-.023z"/>
    </svg>
  )
}

Heart.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

Heart.defaultProps = {
  className: undefined,
  fill: colors.white,
  height: '24px',
  width: '24px',
}

export default Heart