import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { FormRow, FormInput, FormSubmit } from '../../styles/base/form'
import SIGN_IN from '../../graphql/mutation/sign-in'

import actions from '../../redux/actions'

const USER_STATUS = {
  isLoggedIn: true,
  isReady: true,
}

const LoginForm = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  let history = useHistory()

  const station = props.favouriteStation

  const [signIn, { loading }] = useMutation(SIGN_IN, {
    onError: error => {
      setError(error.graphQLErrors[0].message)
    },
    onCompleted: data => {
      if (!data.signIn) return

      const token = data.signIn.token
      localStorage.setItem('userToken', token)

      dispatch(actions.user.setUserStatus(USER_STATUS))

      history.push(props.from.pathname)
    }
  })

  const validateForm = () => {
    return email.length > 0 && password.length > 0
  }
  
  const onSubmit = e => {
    e.preventDefault()

    signIn({
      variables: {
        input: {
          email,
          password,
        }
      }
    })
  }

  return (
    <section>
      {!!error && <p>{error}</p>}

      {user.isReady && user.isLoggedIn && (
        <p>You are already logged in.</p>
      )}

      {user.isReady && !user.isLoggedIn && (
        <>
          {props.from.pathname === '/submit' && (
            <p>You must login to submit a station.</p>
          )}

          {station && station.id && (
            <p>You must login to save stations to your favourite.</p>
          )}

          <form onSubmit={onSubmit}>
            <FormRow>
              <FormInput
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                aria-label="Email"
              />
            </FormRow>

            <FormRow>
              <FormInput
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
                aria-label="Password"
              />
            </FormRow>

            <FormSubmit type="submit" name="submit" disabled={!validateForm(email, password)}>
              {loading ? 'Processing...' : 'Login' }
            </FormSubmit>
          </form>
        </>
      )}
    </section>
  )
}

LoginForm.propTypes = {
  from: PropTypes.object,
  favouriteStation: PropTypes.object,
}

export default LoginForm