import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { spaces } from '../styles/utils'
import Card from '../components/Card/Card'
import { H2 } from '../styles/base/typography'
import LoginForm from '../components/Form/LoginForm'

function SignIn() {
  const location = useLocation()
  const { from } = location.state || {from: { pathname: '/dashboard' }}
  const { favouriteStation } = location.state || {favouriteStation: { id: '' }}

  return (
    <Container>
      <Wrapper>
        <Card>
          <StyledH2>Sign In</StyledH2>
          <LoginForm from={from} favouriteStation={favouriteStation} />
        </Card>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: ${spaces.large} 0;
`

const Wrapper = styled.div`
  padding: 0 ${spaces.medium};
`

const StyledH2 = styled(H2)`
  margin-bottom: 32px;
  text-align: center;
`

export default SignIn