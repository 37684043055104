const units = {
  tiny: 8,
  small: 16,
  medium: 24,
  large: 48,
  huge: 72,
  gigantic: 96,
}

export const spaces = {
  tiny: `${units.tiny}px`,
  small: `${units.small}px`,
  medium: `${units.medium}px`,
  large: `${units.large}px`,
  huge: `${units.huge}px`,
  gigantic: `${units.gigantic}px`,
}