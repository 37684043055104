import React from 'react'
import styled from 'styled-components'

import { spaces } from '../styles/utils'
import Card from '../components/Card/Card'
import { H2 } from '../styles/base/typography'
import SignUpForm from '../components/Form/SignUpForm'

function Join() {
  return (
    <Container>
      <Wrapper>
        <Card>
          <StyledH2>Join</StyledH2>
          <SignUpForm />
        </Card>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: ${spaces.large} 0;
`

const Wrapper = styled.div`
  padding: 0 ${spaces.medium};
`

const StyledH2 = styled(H2)`
  margin-bottom: 32px;
  text-align: center;
`

export default Join