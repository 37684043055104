const DEFAULT_STATE = {
  isLoggedIn: false,
  isReady: false,
}

const user = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_USER_STATUS':
      return {
        ...state,
        ...action.status
      }
    default:
      return state
  }
}

export default user
