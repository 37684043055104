const DEFAULT_STATE = {
  stationIds: [],
}

const userFavourite = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_USER_FAVOURITE_STATIONS':
      return {
        ...state,
        stationIds: action.value.stationIds,
      }
    default:
      return state
  }
}

export default userFavourite
