import { gql } from '@apollo/client'

const REMOVE_FROM_FAVOURITE = gql`
  mutation RemoveFromFavourite($input: FavouriteInput!) {
    removeFromFavourite(input: $input) {
      favouriteStationIds
    }
  }
`

export default REMOVE_FROM_FAVOURITE