import React from 'react'
import PropTypes from 'prop-types'

import { colors } from '../../styles/utils'

function Close({ fill, height, width }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" style={{height, width}}>
      <defs/>
      <path fill={fill} d="M5 18L19.142 3.858l1.414 1.414L6.414 19.414z"/>
      <path fill={fill} d="M6 4l14.142 14.142-1.414 1.414L4.586 5.414z"/>
    </svg>
  )
}

Close.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

Close.defaultProps = {
  className: undefined,
  fill: colors.white,
  height: '24px',
  width: '24px',
}

export default Close