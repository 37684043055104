import { gql } from '@apollo/client'

const GET_ME = gql`
  query Me {
    me {
      id
      email
      favouriteStationIds
    }
  }
`

export default GET_ME