import { gql } from '@apollo/client'

const GET_STATIONS = gql`
  query Stations(
    $first: Int,
    $last: Int,
    $before: String,
    $after: String,
    $status: Status,
    $title: String,
    $cities: String,
    $countries: String,
    $sort: String
  ) {
    stations(
      first: $first,
      last: $last,
      before: $before,
      after: $after,
      status: $status,
      title: $title,
      cities: $cities,
      countries: $countries,
      sort: $sort
    ) {
      edges {
        cursor
        node {
          id
          title
          cities
          countries
          websiteUrl
        }
      }
      totalCount
      startIndex
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export default GET_STATIONS