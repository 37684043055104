import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { FormRow, FormInput, FormSubmit } from '../../styles/base/form'

import CREATE_USER from '../../graphql/mutation/create-user'

import actions from '../../redux/actions'

const USER_STATUS = {
  isLoggedIn: true,
  isReady: true,
}

const SignUpForm = () => {
  const location = useLocation()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [role, setRole] = useState('')
  const [error, setError] = useState(null)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  let history = useHistory()

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onError: error => {
      setError(error.graphQLErrors[0].message)
    },
    onCompleted: result => {
      if (!result.createUser) return

      const token = result.createUser.token
      localStorage.setItem('userToken', token)

      dispatch(actions.user.setUserStatus(USER_STATUS))

      history.push('/dashboard')
    }
  })

  useEffect(() => {
    switch(location.pathname) {
      default:
        setRole('MEMBER')
    }
  }, [location.pathname])

  const validateForm = () => {
    return (email.length > 0 && password.length > 0)
      || (password !== confirm)
  }
  
  const onSubmit = e => {
    e.preventDefault()

    createUser({
      variables: {
        input: {
          firstName,
          lastName,
          email,
          password,
          confirm,
          role,
        }
      }
    })
  }

  return (
    <section>
      {!!error && <p>{error}</p>}

      {user.loggedIn && <p>Logged in</p>}

      <form onSubmit={onSubmit}>
        <FormRow>
          <FormInput
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            placeholder="First Name"
            aria-label="First Name"
          />
        </FormRow>

        <FormRow>
          <FormInput
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            placeholder="Last Name"
            aria-label="Last Name"
          />
        </FormRow>

        <FormRow>
          <FormInput
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            aria-label="Email"
          />
        </FormRow>

        <FormRow>
          <FormInput
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            aria-label="Password"
          />
        </FormRow>

        <FormRow>
          <FormInput
            value={confirm}
            onChange={e => setConfirm(e.target.value)}
            type="password"
            placeholder="Confirm Password"
            aria-label="Confirm Password"
          />
        </FormRow>

        <FormSubmit type="submit" name="submit" disabled={!validateForm()}>
          {loading ? 'Processing...' : 'Sign up' }
        </FormSubmit>
      </form>
    </section>
  )
}

export default SignUpForm