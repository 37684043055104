import { gql } from '@apollo/client'

const CREATE_STATION = gql`
  mutation CreateStation($input: CreateStationInput!) {
    createStation(input: $input) {
      id
      title
      cities
      countries
      isClosedDown
      websiteUrl
      status
    }
  }
`

export default CREATE_STATION