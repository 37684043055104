import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import { colors } from '../../styles/utils'

const PAUSE_DURATION_MS = 800
const PADDING_RIGHT = 16

function TextScrollAnimation(props) {
  const [animation, setAnimation] = useState(null)

  const container = useRef(null)
  const innerContent = useRef(null)

  function setMarqueeEffect() {
    const nodeContainer = container.current
    const nodeInnerContent = innerContent.current

    if (!nodeContainer || !nodeInnerContent) return

    const containerWidth = nodeContainer.offsetWidth
    const scrollWidth = nodeInnerContent.scrollWidth + PADDING_RIGHT

    if (containerWidth > scrollWidth) return

    const overflowWidth = scrollWidth - containerWidth + PADDING_RIGHT
    const transformFrom = 'translateX(0)'
    const transformTo = `translateX(-${overflowWidth}px)`
    const duration = (overflowWidth * 20) + (PAUSE_DURATION_MS * 2)

    const keyframes = [
      {
        offset: 0,
        transform: transformFrom,
      },
      {
        offset: PAUSE_DURATION_MS / duration,
        transform: transformFrom,
      },
      {
        offset: 1 - (PAUSE_DURATION_MS / duration),
        transform: transformTo,
      },
      {
        offset: 1,
        transform: transformTo,
      },
    ]

    const options = {
      duration,
      easing: 'linear',
      iterations: Infinity,
      direction: 'alternate',
    }

    const animation = nodeInnerContent.animate(keyframes, options)

    setAnimation(animation)

    animation.play()
  }

  function resetMarquee() {
    if (!animation) return

    animation.cancel()
  }

  function handleMouseEnterEvent() {
    setMarqueeEffect()
  }

  return (
    <Container
      ref={container}
      onMouseEnter={handleMouseEnterEvent}
      onMouseLeave={resetMarquee}>
      <InnerContent ref={innerContent}>
        {props.children}
      </InnerContent>
    </Container>
  )
}

const Container = styled.div`
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &::after {
    background: linear-gradient(to left, ${colors.black}, rgba(0,0,0,0));
    bottom: 0;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
  }
`

const InnerContent = styled.span`
  display: inline-block;
`

export default TextScrollAnimation