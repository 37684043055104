import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { colors, spaces } from '../styles/utils'
import Content from '../components/Content/Content'
import Sidebar from '../components/Sidebar/Sidebar'

import DashboardContent from '../components/Dashboard/Content'
import { ResetList } from '../styles/base/list'
import { NavLink } from '../styles/base/typography'

export default function Dashboard() {
  let match = useRouteMatch()

  return (
    <>
      <Sidebar>
        <ResetList>
          <NavItem>
            <StyledNavLink to={`${match.url}/components`}>
              Components
            </StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to={`${match.url}/props-v-state`}>
              Props v. State
            </StyledNavLink>
          </NavItem>
        </ResetList>
      </Sidebar>

      <Content>
        <Switch>
          <Route path={`${match.path}/:contentId`} component={DashboardContent} />
          <Route path={match.path}>
            <h3>Please select a topic.</h3>
          </Route>
        </Switch>
      </Content>
    </>
  )
}

const NavItem = styled.li`
  margin-bottom: ${spaces.tiny};

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledNavLink = styled(NavLink)`
  color: ${props => (props.active ? colors.yellow : colors.grey)};
  display: block;
  margin-bottom: ${spaces.tiny};
  padding-left: ${props => (props.active ? '12px' : 0)};
  position: relative;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    color: ${colors.yellow};
    padding-left: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    background-color: ${colors.yellow};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateY(-1px);
    transition: all 0.2s ease;
    width: ${props => (props.active ? '8px' : 0)};
  }

  &:hover::before,
  &:focus::before {
    width: 8px;
  }
`
