import { gql } from '@apollo/client'

const ADD_TO_FAVOURITE = gql`
  mutation AddToFavourite($input: FavouriteInput!) {
    addToFavourite(input: $input) {
      favouriteStationIds
    }
  }
`

export default ADD_TO_FAVOURITE