import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation, useLazyQuery } from '@apollo/client'

import actions from '../../redux/actions'

import { ResetList } from '../../styles/base/list'
import { ListExternalLink, NavButton } from '../../styles/base/typography'
import { colors, spaces } from '../../styles/utils'

import FilledHeart from '../Icons/FilledHeart'
import Heart from '../Icons/Heart'
import TextScrollAnimation from '../TextScrollAnimation/TextScrollAnimation'

import GET_ME from '../../graphql/query/get-me'
import ADD_TO_FAVOURITE from '../../graphql/mutation/add-to-favourite'
import REMOVE_FROM_FAVOURITE from '../../graphql/mutation/remove-from-favourite'

const getLinkAttr = station => {
  if (!station.websiteUrl) return {}

  return {
    href: station.websiteUrl,
    target: '_blank',
    rel: 'noopener noreferrer',
  }
}

function StationsList(props) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const userFavourites = useSelector(state => state.userFavourites)

  const [error, setError] = useState(null)
  const [getMe, { data: userData }] = useLazyQuery(GET_ME)

  const stations = props.stations.edges

  const updateFavouriteStationIds = stationIds => {
    dispatch(actions.user.setUserFavourites({
      stationIds
    }))
  }

  const [addToFavourite] = useMutation(ADD_TO_FAVOURITE, {
    onError: error => {
      setError(error.graphQLErrors[0].message)
    },
    onCompleted: data => {
      if (!data.addToFavourite) return
      updateFavouriteStationIds(data.addToFavourite.favouriteStationIds)
    }
  })

  const [removeFromFavourite] = useMutation(REMOVE_FROM_FAVOURITE, {
    onError: error => {
      setError(error.graphQLErrors[0].message)
    },
    onCompleted: data => {
      if (!data.removeFromFavourite) return
      updateFavouriteStationIds(data.removeFromFavourite.favouriteStationIds)
    }
  })

  useEffect(() => {
    if (user.isLoggedIn) return getMe()
    updateFavouriteStationIds([])
  }, [user.isLoggedIn])

  useEffect(() => {
    if (!userData) return
    updateFavouriteStationIds(userData.me.favouriteStationIds)
  }, [userData])

  if (stations.length === 0) {
    return (
      <NotFound>
        <p>There is no results</p>
      </NotFound>
    )
  }

  if (error) {
    return (
      <NotFound>
        <p>{error}</p>
      </NotFound>
    )
  }

  const favouriteEventHandler = stationId => {
    if (!user.isLoggedIn) {
      return history.push({
        pathname: '/login',
        state: {
          from: { pathname: location.pathname },
          favouriteStation: { id: stationId },
        }
      })
    }

    addToFavourite({
      variables: {
        input: { stationId }
      }
    })
  }

  const unfavouriteEventHandler = stationId => {
    removeFromFavourite({
      variables: {
        input: { stationId }
      }
    })
  }

  return (
    <ResetList>
      {stations.map(item => {
        const station = item.node

        return (
          <ListItem key={station.id}>
            <Details>
              <Col>
                <ColWrapper>
                  <h2>
                    <TextScrollAnimation>
                      <ListExternalLink
                        {...getLinkAttr(station)}
                        className={station.websiteUrl ? 'text-link' : ''}
                      >
                        {station.title}
                      </ListExternalLink>
                    </TextScrollAnimation>
                  </h2>
                </ColWrapper>
              </Col>
              <Col>
                <ColWrapper>
                  <TextScrollAnimation>
                    {station.cities.map((city, index) => {
                      const length = station.cities.length - 1

                      return (
                        <React.Fragment key={index}>
                          <NavButton
                            className="text-link"
                            name="city"
                            value={city}
                            onClick={props.filterEventHandler}
                          >
                            {city}
                          </NavButton>
                          {index < length ? ', ' : ''}
                        </React.Fragment>
                      )
                    })}
                  </TextScrollAnimation>
                </ColWrapper>
              </Col>
              <Col>
                <ColWrapper>
                  <TextScrollAnimation>
                    {station.countries.map((country, index) => {
                      const length = station.countries.length - 1

                      return (
                        <React.Fragment key={index}>
                          <NavButton
                            className="text-link"
                            name="country"
                            value={country}
                            onClick={props.filterEventHandler}
                          >
                            {country}
                          </NavButton>
                          {index < length ? ', ' : ''}
                        </React.Fragment>
                      )
                    })}
                  </TextScrollAnimation>
                </ColWrapper>
              </Col>
            </Details>

            <ButtonGroup>
              {!userFavourites.stationIds.includes(station.id) &&
                <Button onClick={() => favouriteEventHandler(station.id)}>
                  <Heart height={'24px'} width={'24px'} />
                </Button>
              }

              {userFavourites.stationIds.includes(station.id) &&
                <Button onClick={() => unfavouriteEventHandler(station.id)}>
                  <FilledHeart height={'24px'} width={'24px'} />
                </Button>
              }
            </ButtonGroup>
          </ListItem>
        )
      })}
    </ResetList>
  )
}

const NotFound = styled.div`
  padding: ${spaces.small} 0;
`

const ListItem = styled.li`
  align-items: center;
  border-bottom: 1px solid ${colors.white};
  display: flex;
  height: 50px;
  justify-content: space-between;
`

const Details = styled.div`
  align-items: center;
  display: flex;
  width: calc(100% - 40px);
`

const Col = styled.div`
  width: 33.33%;
`

const ColWrapper = styled.div`
  padding-right: 16px;
`

const ButtonGroup = styled.div`
  flex-shrink: 0;
`

const Button = styled.button`
  align-items: center;
  background-color: transparent;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 0;
  width: 40px;

  svg path {
    transition: fill 0.1s ease;
  }

  &:focus,
  &:hover {
    svg path {
      fill: ${colors.grey};
    }
  }
`

StationsList.propTypes = {
  setFilterState: PropTypes.func,
  filterEventHandler: PropTypes.func
}

export default StationsList
