import { createGlobalStyle } from 'styled-components'
import { colors, spaces } from '../utils'
import HKGroteskMedium from '../../fonts/HKGrotesk-Medium.woff'
import RobotoMonoRegular from '../../fonts/RobotoMono-Regular.woff'

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'HK Grotesk Medium';
      src: url(${HKGroteskMedium}) format('woff');
  }

  @font-face {
      font-family: 'Roboto Mono Regular';
      src: url(${RobotoMonoRegular}) format('woff');
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background-color: ${colors.black};
    color: ${colors.white};
    font-family: 'HK Grotesk Medium', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 21px;
  }

  a {
    color: inherit;
    outline: none;
  }

  main {
    margin-top: 50px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    background-color: ${colors.white};
    border: 0;
    color: ${colors.black};
    cursor: pointer;
    outline: none;
    padding: ${spaces.tiny} ${spaces.small};

    &[disabled] {
      cursor: initial;
    }
  }

  input {
    background-color: transparent;
    border: 0;
    color: ${colors.white};
    margin: 0;
    outline: none;
    padding: 0;
  }

  .text-link {
    color: ${colors.white};
    transition: color 0.1s ease;
    outline: none;

    &:focus,
    &:hover {
      color: ${colors.grey};
    }
  }
`

export default GlobalStyle
