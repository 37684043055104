import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, spaces } from '../../styles/utils'
import { InputTypo } from '../../styles/base/typography'
import { ResetButton } from '../../styles/base/button'

import Close from '../Icons/Close'

function StationFilter(props) {
  const { title, city, country } = props

  const keyPressHandler = e => {
    const key = e.key
    if (key !== 'Enter') return
    props.onSubmit(e)
  }

  return (
    <Container>
      <Form autoComplete="off">
        <Col>
          <ColWrapper>
            <StyledInput
              name="title"
              type="text"
              value={title}
              onChange={props.setFilterState}
              onKeyPress={keyPressHandler}
              placeholder="Title"
              aria-label="Title" />

            <CloseButton onClick={props.resetEventHandler} data-reset="title" disabled={!title}>
              <Close fill={'grey'} height={'12px'} width={'12px'} />
            </CloseButton>
          </ColWrapper>
        </Col>
        <Col>
          <ColWrapper>
            <StyledInput
              name="city"
              type="text"
              value={city}
              onChange={props.setFilterState}
              onKeyPress={keyPressHandler}
              placeholder="City"
              aria-label="City" />

            <CloseButton onClick={props.resetEventHandler} data-reset="city" disabled={!city}>
              <Close fill={'grey'} height={'12px'} width={'12px'} />
            </CloseButton>
          </ColWrapper>
        </Col>
        <Col>
          <ColWrapper>
            <StyledInput
              name="country"
              type="text"
              value={country}
              onChange={props.setFilterState}
              onKeyPress={keyPressHandler}
              placeholder="Country"
              aria-label="Country" />

            <CloseButton onClick={props.resetEventHandler} data-reset="country" disabled={!country}>
              <Close fill={'grey'} height={'12px'} width={'12px'} />
            </CloseButton>
          </ColWrapper>
        </Col>
      </Form>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.white};
  display: flex;
  height: 50px;
  padding-right: 40px;
`

const Form = styled.form`
  align-items: center;
  display: flex;
  width: 100%;
`

const Col = styled.div`
  width: 33.33%;
`

const ColWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-right: 16px;
`

const StyledInput = styled(InputTypo)`
  padding: ${spaces.small} 0;
`

const CloseButton = styled(ResetButton)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: ${spaces.small} 0;
  transition: opacity 0.2s ease;
  width: 24px;

  &:disabled {
    display: none;
    opacity: 0;
  }

  &:hover svg path,
  &:focus svg path {
    fill: ${colors.white};
  }

  svg {
    pointer-events: none;
  }

  svg path {
    transition: fill 0.2s ease;
  }
`

StationFilter.propTypes = {
  title: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  setFilterState: PropTypes.func,
  runFilter: PropTypes.func,
  resetEventHandler: PropTypes.func,
}

export default StationFilter
