import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ResetButton } from './button'
import { ResetLink } from './link'

export const H2 = styled.h2`
  font-family: 'HK Grotesk Medium', Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
`

export const NavLink = styled(Link)`
  font-family: 'HK Grotesk Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
  text-decoration: none;
`

export const NavButton = styled(ResetButton)`
  font-family: 'HK Grotesk Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
  text-decoration: none;
`

export const ListExternalLink = styled(ResetLink)`
  font-family: 'HK Grotesk Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 21px;
  vertical-align: middle;
`

export const InputTypo = styled.input`
  font-family: 'HK Grotesk Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
  width: 100%;
`

export const ButtonTypo = styled.button`
  font-family: 'HK Grotesk Medium', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
`
