const setUserStatus = status => ({
  type: 'SET_USER_STATUS',
  status,
})

const setUserFavourites = staionIds => ({
  type: 'SET_USER_FAVOURITE_STATIONS',
  value: staionIds,
})

export default {
  setUserStatus,
  setUserFavourites,
}