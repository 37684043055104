import React from 'react'
import styled from 'styled-components'
import { spaces } from '../../styles/utils'

export default function Content(props) {
  return (
    <Container>
      {props.children}
    </Container>
  )
}

const Container = styled.div`
  margin-left: 300px;
  margin-right: ${spaces.medium};
  padding-top: 32px;
`
