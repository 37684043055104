import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'

require('dotenv').config()

const isProduction = process.env.NODE_ENV === 'production'

const uri = isProduction
  ? process.env.REACT_APP_SERVER_ENDPOINT
  : 'http://localhost:8000/api/graphql'

const httpLink = createHttpLink({ uri })

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('userToken') || null,
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          stations: relayStylePagination(),
        },
      },
    },
  }),
})

export default client