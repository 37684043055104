import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Route } from 'react-router-dom'

const PrivateRoute = ({component: Component, ...rest}) => {
  const user = useSelector(state => state.user)

  if (!user.isReady) return null

  return (
    <Route {...rest} render={props => (
      user.isLoggedIn
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location }}}  />
    )} />
  )
}

export default PrivateRoute