import React from 'react'
import styled from 'styled-components'

import { spaces } from '../styles/utils'
import Card from '../components/Card/Card'
import SubmitForm from '../components/Form/SubmitForm'

function Submit() {
  return (
    <Container>
      <Wrapper>
        <Card>
          <SubmitForm />
        </Card>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: ${spaces.large} 0;
`

const Wrapper = styled.div`
  padding: 0 ${spaces.medium};
`

export default Submit