import { gql } from '@apollo/client'

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      token
      user {
        id
        email
        role
      }
    }
  }
`

export default CREATE_USER