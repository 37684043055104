import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'

import { H2 } from '../../styles/base/typography'
import { FormRow, FormInput, FormSubmit } from '../../styles/base/form'

import CREATE_STATION from '../../graphql/mutation/create-station'

const SubmitForm = () => {
  const location = useLocation()

  const [title, setTitle] = useState('')
  const [status, setStatus] = useState('')
  const [cities, setCities] = useState([])
  const [countries, setCountries] = useState([])
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [isClosedDown] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const inputCities = useRef(null);
  const inputCountries = useRef(null);

  const [createStation, { loading }] = useMutation(CREATE_STATION, {
    onError: error => {
      setError(error.graphQLErrors[0].message || error.message)
    },
    onCompleted: result => {
      setSuccess(true)
    },
  })

  useEffect(() => {
    switch(location.pathname) {
      default:
        setStatus('PENDING')
    }
  }, [location.pathname])

  const validateForm = () => {
    return !!title.length
  }

  const onSubmit = e => {
    e.preventDefault()

    createStation({
      variables: {
        input: {
          title,
          cities,
          countries,
          status,
          websiteUrl,
          isClosedDown,
        }
      }
    })
  }

  const resetForm = () => {
    setTitle('')
    setWebsiteUrl('')
    setSuccess(false)
  }

  function Success() {
    return (
      <div>
        <p style={{ marginBottom: '10px' }}>
          Thank you for your contribution.
        </p>

        <FormSubmit onClick={resetForm}>
          Submit another station
        </FormSubmit>
      </div>
    )
  }

  function arrayInputChange(inputEl) {
    const splitValues = inputEl.current.value.split(',')
    const array = splitValues.map(item => item.trim())

    switch(inputEl) {
      case inputCities:
        setCities(array)
        break;
      case inputCountries:
        setCountries(array)
        break;
      default:
    }
  }

  return (
    <section>
      <StyledH2>
        {success ? 'Success!!' : 'Submit Station'}
      </StyledH2>

      {!!error && <p>{error}</p>}
      {success && <Success />}

      {!success && (
        <form onSubmit={onSubmit}>
          <FormRow>
            <FormInput
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder="Title (required)"
              aria-label="Title"
            />
          </FormRow>

          <FormRow>
            <FormInput
              type="text"
              ref={inputCities}
              onChange={() => arrayInputChange(inputCities)}
              placeholder="Cities (separate by comma)"
              aria-label="Cities"
            />
          </FormRow>

          <FormRow>
            <FormInput
              type="text"
              ref={inputCountries}
              onChange={() => arrayInputChange(inputCountries)}
              placeholder="Countries (separate by comma)"
              aria-label="Countries"
            />
          </FormRow>

          <FormRow>
            <FormInput
              type="text"
              value={websiteUrl}
              onChange={e => setWebsiteUrl(e.target.value)}
              placeholder="Website URL"
              aria-label="Website URL"
            />
          </FormRow>

          <FormSubmit type="submit" name="submit" disabled={!validateForm()}>
            {loading ? 'Submitting...' : 'Submit' }
          </FormSubmit>
        </form>
      )}
    </section>
  )
}

const StyledH2 = styled(H2)`
  margin-bottom: 32px;
  text-align: center;
`

export default SubmitForm