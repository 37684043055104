import { gql } from '@apollo/client'

const SIGN_IN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      token
      user {
        id
        email
        role
      }
    }
  }
`

export default SIGN_IN