import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { NavButton, NavLink } from '../../styles/base/typography'
import { breakpoints, colors, spaces } from '../../styles/utils'
import { logout } from '../../helpers/user'
import actions from '../../redux/actions'

const USER_STATUS = {
  isLoggedIn: false,
  isReady: true,
}

const Header = () => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const logoutHandler = () => {
    logout()
    dispatch(actions.user.setUserStatus(USER_STATUS))
  }

  return (
    <Container>
      <Wrapper>
        <Logo>
          <LogoLink to="/" className="text-link">
            r-a-d-i-o
          </LogoLink>
        </Logo>

        <Nav>
          <HeaderNavLink to="/submit" className="text-link">
            Submit
          </HeaderNavLink>

          {user.isLoggedIn && (
            <HeaderNavLink to="/dashboard" className="text-link">
              Dashboard
            </HeaderNavLink>
          )}

          {!user.isLoggedIn && (
            <>
              <HeaderNavLink to="/login" className="text-link">
                Sign in
              </HeaderNavLink>
              <HeaderNavLink to="/join" className="text-link">
                Create an account
              </HeaderNavLink>
            </>
          )}

          {user.isLoggedIn && (
            <HeaderNavButton onClick={logoutHandler} className="text-link">
              Log out
            </HeaderNavButton>
          )}
        </Nav>
      </Wrapper>
    </Container>
  )
}

const Container = styled.header`
  background-color: ${colors.black};
  border-bottom: 1px solid ${colors.white};
  box-sizing: border-box;
  height: 40px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  @media ${breakpoints.laptop} {
    height: 50px;
  }
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 ${spaces.medium};
`;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
`

const Logo = styled.h1`
  margin: 0;
`

const LogoLink = styled(NavLink)`
  vertical-align: middle;
`

const HeaderNavButton = styled(NavButton)`
  margin-right: ${spaces.small};

  &:last-child {
    margin: 0;
  }
`

const HeaderNavLink = styled(NavLink)`
  margin-right: ${spaces.small};

  &:last-child {
    margin: 0;
  }
`

export default withRouter(Header)
