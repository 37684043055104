import React from 'react'
import PropTypes from 'prop-types'

import { colors } from '../../styles/utils'

function FilledHeart({ fill, height, width }) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{height, width}}>
    <defs/>
    <path fill={fill} d="M15.41 4.5a3.978 3.978 0 00-1.928.487 4.192 4.192 0 00-1.489 1.373 4.148 4.148 0 00-2.057-1.599 3.95 3.95 0 00-2.561-.02 4.14 4.14 0 00-2.08 1.567 4.458 4.458 0 00-.53 4.05c.216.505.477.987.78 1.44l6.448 6.952 6.448-6.953c.309-.442.578-.914.802-1.41a4.548 4.548 0 00.224-2.054 4.48 4.48 0 00-.706-1.932 4.231 4.231 0 00-1.478-1.373 4.014 4.014 0 00-1.916-.505l.043-.023z"/>
  </svg>
  )
}

FilledHeart.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

FilledHeart.defaultProps = {
  className: undefined,
  fill: colors.white,
  height: '24px',
  width: '24px',
}

export default FilledHeart