import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, spaces } from '../../styles/utils'
import { NavButton } from '../../styles/base/typography'

function StationSortFilter(props) {
  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const paramTitle = params.get('title')
  const paramCities = params.get('cities')
  const paramCountries = params.get('countries')
  const paramSort = params.get('sort')

  const [sort, setSort] = useState('')

  const sortFilterHandler = e => {
    const dataSort = e.currentTarget.getAttribute('data-sort')
    const queryArray = []

    if (paramTitle) {
      queryArray.push(`title=${paramTitle}`)
    }

    if (paramCities) {
      queryArray.push(`cities=${paramCities}`)
    }

    if (paramCountries) {
      queryArray.push(`countries=${paramCountries}`)
    }

    queryArray.push(dataSort === 'newest' ? '' : `sort=${dataSort}`)

    history.push(`?${queryArray.filter(x => typeof x === 'string' && x.length > 0).join('&')}`)
  }

  useEffect(() => {
    setSort(paramSort)
  }, [location, paramSort])

  return (
    <nav>
      <NavItem data-sort="newest" onClick={sortFilterHandler} active={!sort || sort === 'newest'}>
        Newest
      </NavItem>
      <NavItem data-sort="oldest" onClick={sortFilterHandler} active={sort === 'oldest'}>
        Oldest
      </NavItem>
      <NavItem data-sort="a-to-z" onClick={sortFilterHandler} active={sort === 'a-to-z'}>
        A-Z Ascending
      </NavItem>
      <NavItem data-sort="z-to-a" onClick={sortFilterHandler} active={sort === 'z-to-a'}>
        A-Z Desending
      </NavItem>
    </nav>
  )
}

const NavItem = styled(NavButton)`
  color: ${props => (props.active ? colors.yellow : colors.grey)};
  display: block;
  margin-bottom: ${spaces.tiny};
  padding-left: ${props => (props.active ? '12px' : 0)};
  position: relative;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    color: ${colors.yellow};
    padding-left: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    background-color: ${colors.yellow};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateY(-1px);
    transition: all 0.2s ease;
    width: ${props => (props.active ? '8px' : 0)};
  }

  &:hover::before,
  &:focus::before {
    width: 8px;
  }
`

StationSortFilter.propTypes = {
  StationSortFilter: PropTypes.func,
}

export default StationSortFilter
